//import config from './Development.json';
//import config from './MvpDevelopment.json';
//import config from './EnelDevelopment.json';
//import config from './EnelQA.json';
import config from './EnelProd.json';
//import config from './{{environment}}.json'; //to use with placeholder

export const msalConfig = {
    authority: config.authority,
    clientId: config.clientId,
    redirectUri: document.getElementById('root').baseURI ? document.getElementById('root').baseURI : config.redirectUri,
    navigateToLoginRequestUrl: false,
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};

// Coordinates and required scopes for your web api
export const apiConfig = {
    resourceUri: config.resourceUri,
    //resourceScope: "Enter the API scopes as declared in the app registration 'Expose an Api' blade in the form of 'api://{client_id}/.default'"
    resourceScope: config.resourceScope,
    redirectUri: config.redirectUri,
    restResource: config.resourceUri + '/api',
    signalResource: config.resourceUri + '/hub',
    clientAppVersion: config.clientAppVersion,
    applicationInsightsConnectionString: config.applicationInsightsConnectionString,
    externalLinks: {
        contact: config.externalLinks.contact,
        faq: config.externalLinks.faq,
        support: config.externalLinks.support,
        whoweare: config.externalLinks.whoweare
    }
}

/** 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginRequest = {
    scopes: ["openid", "profile", "offline_access"]
}

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
    scopes: [apiConfig.resourceScope]
}

// Add here scopes for silent token request
export const silentRequest = {
    scopes: ["openid", "profile", apiConfig.resourceScope]
}

export const accessibilityDocUrl = {
    url: config.accessibilityDocUrl
}
